<template>
  <div class="card card-optnd card-cno">
    <b-table
      id="cno-table"
      class="table-optnd table-cno"
      ref="constructionsTable"
      :items="fetchTableItems"
      :fields="constructionsTableFields"
      :sort-by.sync="sortBy"
      :per-page="perPage"
      :current-page="currentPage"
      @refreshed="updateTable"
      empty-text="Nenhuma obra encontrada"
      show-empty
      :selectable="selectable"
      no-select-on-click
      striped>

      <template v-if="selectable" v-slot:head(selected)="data">
        <b-form-group class="m-0 p-0 d-flex align-items-center">
          <b-form-checkbox size="md" v-model="anyRowSelected" @change="toggleAllRows"></b-form-checkbox>
        </b-form-group>
      </template>

      <template v-if="selectable" v-slot:cell(selected)="data">
        <b-form-group class="m-0 p-0 d-flex align-items-center">
          <b-form-checkbox size="md" v-model="data.rowSelected" @change="(checked)=>{rowCheckboxCallback(checked,data)}"></b-form-checkbox>
        </b-form-group>
      </template>

      <template #table-colgroup="scope">
        <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'detalhes' ? '136px' : field.key === 'selected' ? '40px' : ''}" >
      </template>

      <template v-slot:cell(nome)="data">
        <p class="font-weight-500 text-dark mb-0">
          {{ data.item.nome | capitalize }}
        </p>
        <p v-if="data.item.nome_empresarial" class="mt-1 mb-0">{{ data.item.nome_empresarial | capitalize  }}</p>
      </template>

      <template v-slot:head(destinacao)="data">
        <span v-html="data.label"></span>
      </template>

      <template v-slot:cell(destinacao)="data">
        <p class="font-weight-500 text-dark mb-1">{{ data.item.destinacao[0] }}</p>
        <p class="mb-0" v-if="data.item.cnaes_pretty.length">
          <span class="font-weight-500 text-dark">{{ data.item.cnaes_pretty[0] }}</span>: {{ data.item.cnaes_text[0] }}
        </p>
      </template>

      <template v-slot:cell(endereco)="data"> 
        <div class="d-flex align-items-start justify-content-start">
          <span class="mr-2">
            <i class='bi bi-geo-alt-fill text-small text-medium-gray' title="Endereço"></i>
          </span>
          <span>
            <p class="mb-0">
              {{ data.item.tipo_logradouro | capitalize  }} {{ data.item.logradouro | capitalize  }} {{ data.item.numero_logradouro }}<span v-if="data.item.complemento_logradouro"> {{ data.item.complemento_logradouro | capitalize  }}</span>,
              <span v-if="data.item.bairro">{{ data.item.bairro | capitalize  }},</span> 
              <span v-if="data.item.bairro">CEP: <span class="text-nowrap">{{ data.item.cep | fix-cep }},</span></span>
              {{ data.item.nome_municipio | capitalize}} - {{ data.item.uf }}
            </p>
          </span>
        </div>         
      </template>

      <template v-slot:cell(detalhes)="data">
        <div class="d-flex flex-column align-items-end justify-content-center h-100">
          <!-- Menu de ações ---------------------------------------------------------- -->   
          <!-- <b-dropdown size="lg"  variant="cno-actions" toggle-class="text-decoration-none mb-3" menu-class="shadow-sm" right no-caret>
            <template #button-content>
              <i class="bi bi-three-dots-vertical"></i>
            </template>
            <b-dropdown-item>
              Adicionar à lista
            </b-dropdown-item>
          </b-dropdown> -->
          <b-button @click="showDetails(data)" variant="more-details" title="Exibir todos os detalhes da obra">
            Detalhes<i v-if="data.detailsShowing" class="bi bi-chevron-up"></i><i v-if="!data.detailsShowing" class="bi bi-chevron-down"></i>
          </b-button> 
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Buscando obras! </strong>
          <br/>
          Só mais uns segundinhos...
        </div>
      </template>

      <!-- Mais detalhes ---------------------------------------------------------- -->  
      <template v-slot:row-details="data">
        <b-row class="cno-details border-top border-color align-items-start pt-20 pb-5">
          <b-col cols="12" class="px-0">
            <b-row class="mx-0">
              <b-col cols="3" class="px-0 mb-4">
                <div class="cno-details-expanded">
                  <p class="label-details text-dark">Data de início</p>
                  <p class="mb-0">{{ data.item.data_inicio | fix-date }}</p>
                </div>
              </b-col>
              
              <b-col cols="3" class="px-0 mb-4">
                <div class="cno-details-expanded">
                  <p class="label-details text-dark">Data de registro</p>
                  <p class="mb-0">{{ data.item.data_registro | fix-date }}</p>
                </div>
              </b-col>

              <b-col cols="3" class="px-0 mb-4">
                <div class="cno-details-expanded">
                  <p class="label-details text-dark">Tipo de responsável</p>
                  <p class="mb-0">{{ data.item.qualificacao_responsavel }}</p>
                </div>
              </b-col>

              <b-col cols="3" class="px-0 mb-4">
                <div class="cno-details-expanded">
                  <p class="label-details text-dark">Data de início da responsabilidade</p>
                  <p class="mb-0">{{ data.item.data_inicio_responsabilidade | fix-date }}</p>
                </div>
              </b-col>

              <b-col cols="3" class="px-0 mb-4">
                <div class="cno-details-expanded">
                  <p class="label-details text-dark">Data da situação</p>
                  <p class="mb-0">{{ data.item.data_situacao | fix-date }}</p>
                </div>
              </b-col>
                
              <b-col cols="3" class="px-0 mb-4">
                <div class="cno-details-expanded">
                  <p class="label-details text-dark">Situação</p>
                  <span v-if="data.item.situacao == 'Ativa'" class="badge height-22 badge-green">
                    {{ data.item.situacao }}
                  </span>
                  <span v-else class="badge height-22 badge-gray">
                    {{ data.item.situacao }}
                  </span>
                </div>
              </b-col>

              <b-col cols="3" class="px-0 mb-4">
                <div class="cno-details-expanded">
                  <p class="label-details text-dark">Área total</p>
                  <p class="mb-0">{{ data.item.area_total | fix-area }} m²</p>
                </div>
              </b-col>

              <b-col cols="3" class="px-0 mb-4" v-if="data.item.has_cno_vinculado">
                <div class="cno-details-expanded">
                  <p class="label-details text-dark">CNO vinculado</p>
                  <p class="mb-0">{{ data.item.cno_vinculado }}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
            
          <!-- Tabela detalhamento das áreas ---------------------------------------------------------- -->  
          <b-col cols="12" class="px-0 my-1" v-if="data.item.categoria.length">
            <div class="cno-details-expanded">
              <p class="label-details text-dark mb-1">Detalhamento de áreas</p>
              <div class="card card-details" style="max-height:300px;">
                <div class="clip-path-border-box">
                  <b-table
                    :items="buildDetailRows(data.item)"
                    :fields="detailFields"
                    class="table-optnd"
                    tbody-class="td-list border-none-last-trow"
                    striped>
                      <template v-slot:cell(tipoArea)="data">
                        <span class="badge height-22 badge-purple" v-if="data.item.tipoArea == 'Principal'">{{ data.item.tipoArea }}</span>
                        <span class="badge height-22 badge-pink" v-else>{{ data.item.tipoArea }}</span>
                      </template>
                      <template v-slot:cell(metragem)="data">
                        {{ data.item.metragem | fix-area }} m²
                      </template>
                  </b-table>
                </div>
              </div>
            </div>
          </b-col>

          <b-row class="mx-0 mt-4 pb-4">
            <!-- Tabela CNAES Registrados ---------------------------------------------------------- -->  
            <b-col cols="6" class="pl-0 pr-3" v-if="data.item.cnaes_pretty.length">
              <div class="cno-details-expanded">
                <p class="label-details text-dark mb-1">CNAES Registrados</p>
                <div class="card card-details" style="height:100%; max-height:300px;">
                  <div class="clip-path-border-box">
                    <b-table
                      :items="buildDetailCnaesRows(data.item)"
                      :fields="detailCnaesFields"
                      thead-tr-class="bg-white"
                      class="table-optnd table-fixed"
                      tbody-class="td-list"
                      striped>
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{ width: field.key === 'dataRegistro' ? '145px' : ''}" >
                        </template>
                        <template v-slot:cell(cnae)="data">
                          <p class="mb-0 text-left"><span class="font-weight-500 text-dark">{{ data.item.cnae }}</span> - <span class="text-wrap">{{ data.item.cnaesText }}</span></p>
                        </template>
                        <template v-slot:head(dataRegistro)="data">
                          <span v-html="data.label"></span>
                        </template>
                        <template v-slot:cell(dataRegistro)="data">
                          <span>{{ data.item.dataRegistro | fix-date }}</span>
                        </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Localização ---------------------------------------------------------- -->
            <b-col cols="6" class="pl-3 pr-0">
              <div class="cno-details-expanded">
                <p class="label-details text-dark mb-1">Localização da obra</p>
                <div class="card card-details" style="height:100%; max-height:300px;">
                  <cnpj-map-google class="my-0 h-100"
                    :cnpj_location = "data.item.tipo_logradouro + ' ' + data.item.logradouro + ' ' + data.item.numero_logradouro + ', ' + data.item.nome_municipio + '-' + data.item.uf">
                  </cnpj-map-google>    
                </div>
              </div>
            </b-col>

          </b-row>

          <!-- Ficha da empresa responsável ---------------------------------------------------------- -->  
          <b-col cols="12" class="px-0 mt-3" v-if="typeof companyDetails[data.index] !== 'undefined'">
            <div class="cno-details-expanded">
              <p class="label-details text-dark mb-1">Ficha da empresa responsável</p>
              <div class="card card-details border-blue p-20" style="max-height: fit-content;">
                <b-row class="mx-0 mb-3 pb-3 border-bottom border-color">
                  <b-col cols class="pl-0 pr-3 flex-grow-1">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Razão Social</p>
                      <p class="mb-0"><a class="text-blue" :href="`/brazilian_companies/${companyDetails[data.index].cnpj}`" target="_blank">{{ companyDetails[data.index].razao_social | capitalize }}</a></p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3" v-if="companyDetails[data.index].nome_fantasia.length > 0">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Nome Fantasia</p>
                      <p class="mb-0">{{ companyDetails[data.index].nome_fantasia | capitalize }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">CNPJ</p>
                      <p class="mb-0">{{ companyDetails[data.index].cnpj | fix-cnpj}}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Matriz ou Filial</p>
                      <p class="mb-0">
                        <span class="badge height-22 badge-purple" v-if="companyDetails[data.index].matriz_filial == 'Matriz'">
                          {{ companyDetails[data.index].matriz_filial }}
                        </span>
                        <span class="badge height-22 badge-pink" v-else>
                          {{ companyDetails[data.index].matriz_filial }}
                        </span>
                      </p>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mx-0 mb-3 pb-3 border-bottom border-color">
                  <b-col cols="12" class="pl-0 pr-3 mb-4">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">CNAE Fiscal</p>
                      <p class="mb-0">{{ companyDetails[data.index].cnae_fiscal_pretty }} - {{ companyDetails[data.index].cnae_text }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4" v-if="companyDetails[data.index].porte_pretty">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Porte</p>
                      <p class="mb-0">{{ companyDetails[data.index].porte }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Situação Cadastral</p>
                      <p class="mb-0">
                        <span class="badge height-22 badge-green" v-if="companyDetails[data.index].situacao_cadastral == 'Ativa'">
                          {{ companyDetails[data.index].situacao_cadastral }}
                        </span>
                        <span class="badge height-22 badge-gray" v-else>
                          {{ companyDetails[data.index].situacao_cadastral }}
                        </span>
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4" v-if="companyDetails[data.index].data_situacao">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Data da Situação Cadastral</p>
                      <p class="mb-0">{{ companyDetails[data.index].data_situacao | fix-date }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4" v-if="companyDetails[data.index].data_inicio_atividade">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Data de Início da Atividade</p>
                      <p class="mb-0">{{ companyDetails[data.index].data_inicio_atividade | fix-date }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4" v-if="companyDetails[data.index].natureza_juridica">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Natureza Jurídica</p>
                      <p class="mb-0">{{ companyDetails[data.index].natureza_juridica }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4" v-if="companyDetails[data.index].capital_social">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Capital Social</p>
                      <p class="mb-0">R$ {{ companyDetails[data.index].capital_social | fix-capital-social }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4" v-if="companyDetails[data.index].opc_simples">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Simples Nacional</p>
                      <p class="mb-0">{{ companyDetails[data.index].opc_simples }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4" v-if="companyDetails[data.index].opc_mei">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">MEI</p>
                      <p class="mb-0">{{ companyDetails[data.index].opc_mei }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4" v-if="!companyDetails[data.index].opc_mei.startsWith('N') && validDate(companyDetails[data.index].data_opc_mei)">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Data do MEI</p>
                      <p class="mb-0">{{ companyDetails[data.index].data_opc_mei | fix-date }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4" v-if="!companyDetails[data.index].opc_simples.startsWith('N') && validDate(companyDetails[data.index].data_opc_simples)">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Data do Simples</p>
                      <p class="mb-0">{{ companyDetails[data.index].data_opc_simples | fix-date }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3 mb-4" v-if="companyDetails[data.index].opc_simples.startsWith('N') && validDate(companyDetails[data.index].data_exc_simples)">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Data de Exclusão do Simples</p>
                      <p class="mb-0">{{ companyDetails[data.index].data_exc_simples | fix-date }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3" v-if="companyDetails[data.index].opc_mei.startsWith('N') && validDate(companyDetails[data.index].data_exc_mei)">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Data de Exclusão do MEI</p>
                      <p class="mb-0">{{ companyDetails[data.index].data_exc_mei | fix-date }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3" v-if="companyDetails[data.index].situacao_especial">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Situação Especial</p>
                      <p class="mb-0">{{ companyDetails[data.index].situacao_especial_pretty }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3" v-if="companyDetails[data.index].situacao_especial">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Data da Situação Especial</p>
                      <p class="mb-0">{{ companyDetails[data.index].data_sit_especial | fix-date }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3" v-if="companyDetails[data.index].motivo_situacao_pretty">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">Motivo da Situação</p>
                      <p class="mb-0">{{ companyDetails[data.index].motivo_situacao_pretty }}</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mx-0 mb-3 pb-3 border-bottom border-color">
                  <b-col cols="6" class="pl-0 pr-3 mb-4">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray">
                        <span class="mr-2"> <i title="Endereço" class="bi bi-geo-alt-fill text-small text-medium-gray"></i> </span>
                        Endereço
                      </p>
                      <p class="mb-0">
                        {{ companyDetails[data.index].tipo_logradouro | capitalize  }} {{ companyDetails[data.index].logradouro | capitalize  }},
                        {{ companyDetails[data.index].numero }} <span v-if="companyDetails[data.index].complemento"> {{ companyDetails[data.index].complemento | capitalize }} </span>
                        <span v-if="companyDetails[data.index].bairro">, {{ companyDetails[data.index].bairro | capitalize  }},</span> 
                        <span v-if="companyDetails[data.index].bairro">CEP: {{ companyDetails[data.index].cep | fix-cep }}</span>, {{ companyDetails[data.index].municipio }} - {{ companyDetails[data.index].uf }}
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="6" class="pl-0 pr-3 mb-4" v-if="companyDetails[data.index].email">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray"><span class="mr-2"><i title="E-mail" class="bi bi-envelope-fill text-small text-medium-gray"></i></span>Email</p>
                      <p class="mb-0">{{ companyDetails[data.index].email.toLowerCase() }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3" v-if="companyDetails[data.index].telefone_1">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray"><span class="mr-2"><i title="Telefone" class="bi bi-telephone-fill text-small text-medium-gray"></i></span>Telefone 1</p>
                      <p class="mb-0">({{ companyDetails[data.index].ddd_1 }}) {{ companyDetails[data.index].telefone_1 | fix-phone }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3" v-if="companyDetails[data.index].telefone_2">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray"><span class="mr-2"><i title="Telefone" class="bi bi-telephone-fill text-small text-medium-gray"></i></span>Telefone 2</p>
                      <p class="mb-0">({{ companyDetails[data.index].ddd_2 }}) {{ companyDetails[data.index].telefone_2 | fix-phone }}</p>
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-0 pr-3" v-if="companyDetails[data.index].telefone_3">
                    <div class="cno-details-expanded">
                      <p class="label-details text-medium-gray"><span class="mr-2"><i title="Telefone" class="bi bi-telephone-fill text-small text-medium-gray"></i></span>Telefone 3</p>
                      <p class="mb-0">({{ companyDetails[data.index].ddd_3 }}) {{ companyDetails[data.index].telefone_3 | fix-phone }}</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mx-0 mt-1 justify-content-center">
                    <b-button variant="blue" class="mr-3" :href="`/brazilian_companies/${companyDetails[data.index].cnpj}`" target="_blank">
                      <i class="bi bi-eye mr-2"></i>Ver ficha completa da empresa
                    </b-button>
                    <b-button variant="outline-blue" @click="search_similar_companies(companyDetails[data.index])" target="_blank">
                      <i class="bi bi-search mr-2"></i>Pesquisar empresas como essa
                    </b-button>
                </b-row>
              </div>
            </div>          
          </b-col>
        </b-row>        
      </template>
    </b-table>
  </div>
</template>


<script>

export default {  
  props: {
    value: Array,
    currentPage: Number,
    selectable: {
      type: Boolean,
      default: true
    },
    sorting: {
      type: Object,
      default: () => ({field: 'cno', order: 'asc'})
    },
  },
  data () {
    return {
      sortBy: 'nome',
      sortDesc: false,
      perPage: 25,      
      endpoints: {        
        fetchTableItems: '/constructions_data?'        
      },
      constructionsTableFields: [
        { key: 'selected', label: '', sortable: false }, 
        { key: 'nome', label: 'Nome, Nome Empresarial', sortable: false },
        { key: 'destinacao', label: 'Destinação, <span class="font-weight-normal">CNAE da Obra</span>', sortable: false },
        { key: 'endereco', label: 'Endereço', sortable: false },
        { key: 'detalhes', label: '', sortable: false, details_loaded: false}
      ],      
      detailFields: [
        { key: 'categoria', label: 'Categoria' },
        { key: 'destinacao', label: 'Destinação' },
        { key: 'tipoObra', label: 'Tipo de Obra' },
        { key: 'tipoArea', label: 'Tipo de Área' },
        { key: 'metragem', label: 'Metragem' }
      ],
      detailCnaesFields: [
        { key: 'cnae', label: 'CNAE' },
        { key: 'dataRegistro', label: '<span class="text-nowrap">Data de Registro<span>' },
      ],
      currentTableItems: [],      
      tableErrorFlag: false,
      tableErrorResponse: {},
      currentCno: '',
      anyRowSelected: false,
      companyDetails: []
    }
  },  
  methods: {
    refresh() {
      this.$refs.constructionsTable.refresh()      
    },
    rowCheckboxCallback(checked, data) {
      checked ? data.selectRow() : data.unselectRow()      
      this.anyRowSelected = this.rowSelectedCheck()
      this.$emit('input', this.selectedCnos() )
    },
    toggleAllRows(checked) {
      checked ? this.$refs.constructionsTable.selectAllRows() : this.$refs.constructionsTable.clearSelected()
      this.$emit('input', this.selectedCnos() )
    },
    selectedCnos() {
      const table = this.$refs.constructionsTable
      const cnos = table.localItems.map(item => item.cno)
      return cnos.filter((cno, index) => table.isRowSelected(index))
    },
    rowSelectedCheck() {
      if (this.$refs.constructionsTable === undefined) return false
      const table = this.$refs.constructionsTable      
      return [...Array(table.localItems.length).keys()].map((item,index)=>(table.isRowSelected(index))).some(e => {return e})
    },
    markSelectedCnos() {
      if (this.$refs.constructionsTable === undefined) return undefined;
      const table = this.$refs.constructionsTable
      for (const [index, item] of table.localItems.entries()) {        
        if ( this.value.includes(item.cno) ) { table.selectRow(index) }
      }
    },
    updateTable() {
      this.$forceUpdate()      
    },
    fetchTableItems(ctx) {
      const newQuery = this.endpoints.fetchTableItems 
                     + '&currentPage=' + ctx.currentPage
                     + '&perPage=' + ctx.perPage
                     + '&sortBy=' + ctx.sortBy
                     + '&sortDesc=' + ctx.sortDesc
                     + '&apiUrl=' + ctx.apiUrl
                     + '&itemsCount=' + this.itemsCount
      const promise = axios.get(newQuery)
      return promise.then(response => {
        this.tableErrorFlag = false
        let tableItems = this.orderTableItems(response.data.constructions.data.map(item => item.attributes))
        this.currentTableItems = tableItems
        return tableItems
      }).catch( error => {
        this.tableErrorFlag = true
        this.tableErrorResponse = error.response
        return []
      })
    },
    showDetails(data) {
      this.currentCno = data.item.cno
      if ( data.item.ni_responsavel.length > 0 ) {
        let filter = {"cnpj": [data.item.ni_responsavel], "internal": true}
        axios.post('/brazilian_companies_filters/create_for_js', filter)
             .then(response => {
              axios.get('/companies_data?currentPage=1&perPage=25&itemsCount=1')
                   .then(response => {
                    let company = response.data.companies.data[0]
                    if( typeof company !== 'undefined') {
                      this.companyDetails[data.index] = company.attributes
                    }
                    data.toggleDetails()
              })
        })
      } else {
        data.toggleDetails()
      }
    },
    orderTableItems(items) {
      if (this.sorting.sort_field=='data_inicio') {
        items.sort((a,b) => this.compareDates(a,b,this.sorting.sort_order))
      }
      else {
        items.sort((a,b) => Number(a.cno) - Number(b.cno))
      }
      return items
    },
    compareDates(a,b,order) {
      if (order.toLowerCase().startsWith('asc')) {
        return new Date(a.data_inicio) - new Date(b.data_inicio)  
      }
      else {
        return new Date(b.data_inicio) - new Date(a.data_inicio)  
      }
    },
    validDate(dateString) {
      return !(dateString === null) && dateString.length == 12 && !this.isEpoch(dateString)
    },
    search_similar_companies(company) {
      let filter = {"uf": [company.uf],
                    "cnae_fiscal": [company.cnae_fiscal],
                    "include_cnae_secundario": true,   
                    "situacao_cadastral": ["02"]}
      axios.post('/brazilian_companies_filters/create_for_js', filter)
           .then(window.open('/brazilian_companies', '_blank'))
    },
    search_this_company(cnpj) {
      let filter = {"cnpj": [cnpj]}                      
      axios.post('/brazilian_companies_filters/create_for_js', filter)
           .then(window.open('/brazilian_companies', '_blank'))
    },
    buildDetailRows(item) {
      if (!item || !Array.isArray(item.categoria)) {
        return []
      }
      return item.categoria.map((_, idx) => ({
        categoria: item.categoria[idx] || '',
        destinacao: item.destinacao[idx] || '',
        tipoObra: item.tipo_obra[idx] || '',
        tipoArea: item.tipo_area[idx] || '',
        metragem: item.metragens[idx] || ''
      }))
    },
    buildDetailCnaesRows(item) {
      if (!item || !Array.isArray(item.cnaes_pretty)) {
        return []
      }
      return item.cnaes_pretty.map((cnae, idx) => ({
        cnaesText: item.cnaes_text[idx] || '',
        cnae: cnae,
        dataRegistro: item.data_registro_cnaes[idx] || '',
      }))
    },
  },
  updated: function () {
    this.markSelectedCnos()
    this.anyRowSelected = this.rowSelectedCheck()    
  },
  filters: {

  }
}

</script>